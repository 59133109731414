import React, { useState } from "react"
import "./Row.css"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import PlaylistPlayIcon from "@mui/icons-material/PlaylistPlay"
import { useNavigate } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

function Row({ index, refFunc, title, contents = [] }) {
  const [scrollX, setScrollX] = useState(0)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const baseURL = process.env.REACT_APP_CMS_URL

  const handleLeftArrow = () => {
    let x = scrollX + Math.round(window.innerWidth / 2)
    if (x > 0) {
      x = 0
    }

    setScrollX(x)
  }

  const handleRightArrow = () => {
    let x = scrollX - Math.round(window.innerWidth / 2)
    const listW = contents.length * 270
    if (window.innerWidth - listW > x) {
      x = window.innerWidth - listW - 80
    }

    setScrollX(x)
  }
  const shouldScroll = contents.length * 320 > window.innerWidth

  const handleImageClick = (content) => {
    if (content?.content_type === "episode") {
      navigate(`/episodes/${content?.id}`)
      return
    }

    if (content?.content_type === "serie") {
      navigate(`/series/${content?.id}`)
      return
    }
  }

  const loadImage = () => {
    setLoading(false)
  }

  return (
    <div key={index} ref={refFunc} className="row">
      <h2>{title}</h2>
      {shouldScroll && (
        <div>
          <div className="row__left" onClick={handleLeftArrow}>
            <NavigateBeforeIcon style={{ fontSize: 50 }} />
          </div>
          <div className="row__right" onClick={handleRightArrow}>
            <NavigateNextIcon style={{ fontSize: 50 }} />
          </div>
        </div>
      )}
      <div className="row__posters" style={{ marginLeft: scrollX }}>
        {contents.map((content, i) => (
          <div key={i} className="row__posterContainer">
            {content?.content_type === "serie" && !loading && (
              <PlaylistPlayIcon
                style={{
                  position: "absolute",
                  margin: 10,
                  top: 0,
                  left: 0,
                  color: "grey",
                  backgroundColor: "black",
                  fontSize: 30,
                  opacity: 0.7,
                  zIndex: 100,
                }}
              />
            )}
            <LazyLoadImage
              effect="blur"
              width={250}
              height={375}
              onLoad={loadImage}
              src={encodeURI(
                `${baseURL}${content?.thumbnail?.sizes?.poster?.url}`,
              )}
              alt={content?.thumbnail?.alt}
              onClick={() => handleImageClick(content)}
            />
            {!loading && (
              <div
                className="row__progress"
                style={{ width: `${content?.watched_percentage * 100}%` }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Row
