import React, { useEffect, useRef, useState } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import "./EpisodeScreen.css"
import Nav from "../components/Nav"
import { useParams } from "react-router-dom"
import ReactPlayer from "react-player"
import requests from "../api/requests"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { Skeleton } from "@mui/material"

const baseURL = process.env.REACT_APP_CMS_URL

function EpisodeScreen() {
  const [lastPlayedValue, setLastPlayedValue] = useState(0.0)
  const playerRef = useRef()

  const [content, setContent] = useState({
    title: "",
    description: "",
    attachment: null,
  })
  const [meta, setMeta] = useState({
    watched: false,
    percentage_watched: 0.0,
  })

  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    const fetchEpisode = async () => {
      try {
        const response = await requests.getEpisode(id)
        setContent(response.data?.episode)
        setMeta(response.data?.meta)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchEpisode()
  }, [id])

  const onDuration = () => {
    if (meta.watched) return
    playerRef.current.seekTo(meta?.percentage_watched || 0.0, "fraction")
  }

  const [showFullDescription, setShowFullDescription] = useState(
    content?.description?.length < 300
  )
  const [hasShortDescription, setHasShortDescription] = useState(false)

  const description = content?.description

  const limitedDescription = showFullDescription
    ? description
    : description?.substring(0, 300) + "..."

  const saveWatchedProgress = async (played, watch) => {
    try {
      await requests.setEpisodeWatched(content?.id, {
        watched: watch,
        percentage_watched: played,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onProgress = (state) => {
    if (state.played > lastPlayedValue + 0.1) {
      if (state.played > 0.8) {
        saveWatchedProgress(state.played, true)
        return
      }
      saveWatchedProgress(state.played, false)
      setLastPlayedValue(state.played)
    }
  }

  const onEnded = (state) => {
    saveWatchedProgress(1, true)
    setLastPlayedValue(1)
  }

  const openAttachment = (url) => {
    window.open(`${baseURL}${url}`, "_blank")
  }

  const getFilesize = (size) => {
    const kbSize = size / 1024
    if (kbSize < 1024) {
      return `${kbSize.toFixed(2)} KB`
    }

    return `${(kbSize / 1024).toFixed(2)} MB`
  }

  const skeletonLoader = (
    <div className="episodeScreen__body">
      <Skeleton variant="rect" width={"90vh"} height={"auto"} style={
        {
          aspectRatio: "16/9",
          maxHeight: "90vh",
          maxWidth: "90vw",
        }
      } />
      <div className="episodeScreen__info">
        <Skeleton variant="text" width={"50%"} height={"50px"} />
      </div>
      <Skeleton variant="text" width={"100%"} height={"100px"} />
    </div>
  )


  return (
    <div className="episodeScreen">
      <Nav />
      { isLoading ? skeletonLoader : <div className="episodeScreen__body">
        <button onClick={() => (window.location.href = "/")}>
          <div className="episodeScreen__back">
            <ArrowBackIcon /> Voltar ao início
          </div>
        </button>
        <ReactPlayer
          ref={(player) => (playerRef.current = player)}
          width={"90vh"}
          height={"auto"}
          style={{
            aspectRatio: "16/9",
            maxHeight: "90vh",
            maxWidth: "90vw",
          }}
          playing
          controls
          url={content?.video_hls}
          onDuration={onDuration}
          onProgress={onProgress}
          onEnded={onEnded}
        />
        <div className="episodeScreen__info">
          <h1>{content?.title}</h1>
        </div>
        <div className="episodeScreen__description">
          <h4>{limitedDescription}</h4>
          {!showFullDescription && (
            <button
              onClick={() => {
                setShowFullDescription(true)
                setHasShortDescription(true)
              }}
            >
              Mostrar Mais
              <KeyboardArrowDownIcon />
            </button>
          )}
          {hasShortDescription && (
            <button
              onClick={() => {
                setShowFullDescription(false)
                setHasShortDescription(false)
              }}
            >
              Mostrar Menos
              <KeyboardArrowUpIcon />
            </button>
          )}
        </div>

        {content?.attachment !== null && (
          <div
            className="episodeScreen_attachment"
            onClick={() => openAttachment(content.attachment.url)}
          >
            <PictureAsPdfIcon />
            <div className="episodeScreen_attachment__info">
              <h5 href={`${baseURL}${content?.attachment?.url}`}>
                {content.attachment.filename}
              </h5>
              <p>{getFilesize(content.attachment.filesize)}</p>
            </div>
          </div>
        )}
      </div>
      }
    </div>
  )
}

export default EpisodeScreen
