import React, { useCallback, useEffect, useRef, useState } from "react"
import Nav from "../components/Nav"
import Banner from "../components/Banner"
import Row from "../components/Row"
import requests from "../api/requests"
import { logout } from "../app/features/userSlice"
import { useDispatch } from "react-redux"
import { useSnackbar } from "react-simple-snackbar"
import { errorSnackBar } from "../components/snackBarOptions"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "@mui/material"

function HomeScreen() {
  const [sections, setSections] = useState([])
  const [bannerEpisodes, setBannerEpisodes] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)

  const observer = useRef()
  const sectionRef = useCallback(
    (node, index) => {
      sectionsRef.current[index] = node

      if (sections.length === index + 1) {
        if (loading || isLoadingNextPage) return
        if (observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && hasMore) {
            setPage((prev) => prev + 1)
          }
        })

        if (node) observer.current.observe(node)
      }
    },
    [sections, loading, hasMore, isLoadingNextPage],
  )

  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sectionsRef = useRef([])

  const scrollToSection = (index) => {
    const headerOffset = 80
    const elementPosition =
      sectionsRef.current[index]?.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset

    window.scrollTo({ top: offsetPosition, behavior: "smooth" })
  }

  useEffect(() => {
    const handleAPIError = (error) => {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      if (error.response.status === 403) {
        const planStatus = error?.response?.data?.status
        if (planStatus === "not_subscribed") {
          navigate("/plan")
          return
        }

        navigate("/unpaid")
        return
      }

      openErrorSnackbar("Ocorreu um erro inexperado")
      return
    }

    async function fetchData() {
      try {
        if (page > 1) setIsLoadingNextPage(true)
        const req = await requests.getSections(page, 5)
        setSections((prev) => [...prev, ...req.data.sections])
        setHasMore(req.data.hasNextPage)
        if (page === 1) setBannerEpisodes(req.data.bannerEpisodes)

        return
      } catch (error) {
        handleAPIError(error)
      } finally {
        setLoading(false)
        setIsLoadingNextPage(false)
      }

      return
    }

    fetchData()
    return
  }, [page, dispatch, navigate])

  const loadingRow = (
    <div>
      <Skeleton
        variant="text"
        height={"50px"}
        width={"300px"}
        animation={"wave"}
        style={{ margin: "20px 20px 0px 20px" }}
      />
      <div
        style={{
          margin: "20px 40px 0px 40px",
          display: "flex",
          overflowX: "hidden",
          overflowY: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {[...Array(10)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rect"
            height={"375px"}
            width={"250px"}
            animation={"wave"}
            style={{
              minWidth: "250px",
              minHeight: "375px",
              display: "inline-block",
              marginRight: "10px",
              objectFit: "contain",
            }}
          />
        ))}
      </div>
    </div>
  )

  const loadingPage = (
    <div style={{overflow: "hidden"}}>
    <Nav />
    <div style={{ margin: "80px 20px 0px 20px" }}>
      <Skeleton
        variant="rect"
        height={"350px"}
        width={"100%"}
        animation={"wave"}
      />
    </div>
    {loadingRow}
  </div>
  )

  return !loading ? (
    <div
      style={{
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <Nav
        sections={sections}
        scrollFunc={scrollToSection}
        hideSumary={false}
      />
      {bannerEpisodes && <Banner episodes={bannerEpisodes} />}
      {sections.map((section, i) => {
        if (section?.contents?.length === 0) return null
        return (
          <Row
            key={i}
            index={section?._id}
            refFunc={(el) => sectionRef(el, i)}
            title={section?.title}
            contents={section?.contents}
          />
        )
      })}
      {isLoadingNextPage && loadingRow}
    </div>
  ) : (
    loadingPage
  )
}

export default HomeScreen
