import React, { useEffect, useRef, useState } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import "./SerieDesktop.css"
import { useParams } from "react-router-dom"
import ReactPlayer from "react-player"
import requests from "../../api/requests"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { Skeleton } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const baseURL = process.env.REACT_APP_CMS_URL

function SerieDesktop() {
  const [lastPlayedValue, setLastPlayedValue] = useState(0.0)
  const [lastEpisode, setLastEpisode] = useState(null)
  const [actualEpisode, setActualEpisode] = useState(null)

  const playerRef = useRef()

  const [content, setContent] = useState({
    episodes: [],
  })
  const [meta, setMeta] = useState({
    watched: false,
    percentage_watched: 0.0,
    serie_percentage_watched: 0.0,
  })

  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  const getEpisodeIndex = (id) => {
    return content.episodes.findIndex((episode) => episode.id === id)
  }

  useEffect(() => {
    const getEpisode = (episodes, id) => {
      return episodes.find((episode) => episode.id === id)
    }

    const fetchEpisode = async () => {
      try {
        const response = await requests.getSeries(id)
        setContent(response.data?.serie)
        setMeta(response.data?.meta)
        setActualEpisode(response.data?.serie?.episodes[0])
        setLastEpisode(
          response.data?.serie?.episodes[
            response.data?.serie?.episodes.length - 1
          ],
        )

        if (!response.data?.meta?.watched && response.data?.meta?.episode_id) {
          const episodes = response.data?.serie?.episodes
          const episode = getEpisode(episodes, response.data.meta.episode_id)
          setActualEpisode(episode)
        }
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    fetchEpisode()
  }, [id])

  useEffect(() => {
    setShowFullDescription(actualEpisode?.description?.length < 300)
  }, [actualEpisode])

  const onDuration = () => {
    if (meta.watched) return
    playerRef.current.seekTo(meta?.percentage_watched || 0.0, "fraction")
  }

  const [showFullDescription, setShowFullDescription] = useState(false)
  const [hasShortDescription, setHasShortDescription] = useState(false)

  const description = actualEpisode?.description

  const limitedDescription = showFullDescription
    ? description
    : description?.substring(0, 300) + "..."

  const saveWatchedProgress = async (played, watch) => {
    const totalEpisodes = content?.episodes?.length
    const actualEpisodeIndex = getEpisodeIndex(actualEpisode?.id)
    const seriePercentageWatched = (actualEpisodeIndex + 1) / totalEpisodes
    try {
      await requests.setEpisodeWatched(actualEpisode?.id, {
        serie_id: content?.id,
        watched: watch,
        percentage_watched: played,
        serie_percentage_watched: seriePercentageWatched,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onProgress = (state) => {
    if (state.played > lastPlayedValue + 0.1) {
      if (state.played > 0.8 && actualEpisode?.id === lastEpisode?.id) {
        saveWatchedProgress(state.played, true)
        return
      }
      saveWatchedProgress(state.played, false)
      setLastPlayedValue(state.played)
    }
  }

  const onEnded = (state) => {
    if (actualEpisode?.id === lastEpisode?.id) {
      saveWatchedProgress(1, true)
      setLastPlayedValue(1)
      return
    }

    const nextEpisodeIndex = getEpisodeIndex(actualEpisode?.id) + 1
    const nextEpisode = content?.episodes[nextEpisodeIndex]
    setActualEpisode(nextEpisode)
    playerRef.current.seekTo(0.0, "fraction")
    setLastPlayedValue(0.0)
  }

  const openAttachment = (url) => {
    window.open(`${baseURL}${url}`, "_blank")
  }

  const changeEpisode = (episode) => {
    if (episode.id === actualEpisode?.id) return
    setShowFullDescription(true)
    setHasShortDescription(false)
    setActualEpisode(episode)
    playerRef.current.seekTo(0.0, "fraction")
    setLastPlayedValue(0.0)
  }

  const getFilesize = (size) => {
    const kbSize = size / 1024
    if (kbSize < 1024) {
      return `${kbSize.toFixed(2)} KB`
    }

    return `${(kbSize / 1024).toFixed(2)} MB`
  }

  // todo: create a skeleton loader for this screen
  const skeletonLoader = (
    <div className="serieScreen__body">
      <div className="serieScreen__video">
        <Skeleton
          variant="rect"
          width={"auto"}
          height={"400px"}
          style={{
            aspectRatio: "16/9",
          }}
        />
        <div className="serieScreen__info">
          <Skeleton variant="text" width={"50%"} height={"50px"} />
        </div>
        <Skeleton variant="text" width={"100%"} height={"100px"} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "400px",
          width: "20vw",
          minWidth: "200px",
          marginTop: "100px",
        }}
      >
        <h4>
          <Skeleton variant="text" width={"100px"} height={"50px"} />
        </h4>
        {[1, 2, 3].map((episode) => (
          <div className="serieScreen__playlist__info">
            <Skeleton variant="rect" width={"100%"} height={"60px"} />
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div className="serieScreen">
      {isLoading ? (
        skeletonLoader
      ) : (
        <div className="serieScreen__body">
          <div className="serieScreen__video">
            <button onClick={() => (window.location.href = "/")}>
              <div className="serieScreen__back">
                <ArrowBackIcon /> Voltar ao início
              </div>
            </button>
            <div className="serieScreen__player">
              <ReactPlayer
                ref={(player) => (playerRef.current = player)}
                width={"auto"}
                height={"400px"}
                style={{
                  aspectRatio: "16/9",
                }}
                playing
                controls
                url={actualEpisode?.video_hls}
                onDuration={onDuration}
                onProgress={onProgress}
                onEnded={onEnded}
              />
            </div>
            <div className="serieScreen__info">
              <h1>
                {actualEpisode?.title?.length < 50
                  ? actualEpisode?.title
                  : actualEpisode?.title?.substring(0, 150) + "..."}
              </h1>
            </div>
            <div className="serieScreen__description">
              <h4>{limitedDescription}</h4>
              {!showFullDescription && (
                <button
                  onClick={() => {
                    setShowFullDescription(true)
                    setHasShortDescription(true)
                  }}
                >
                  Mostrar Mais
                  <KeyboardArrowDownIcon />
                </button>
              )}
              {hasShortDescription && (
                <button
                  onClick={() => {
                    setShowFullDescription(false)
                    setHasShortDescription(false)
                  }}
                >
                  Mostrar Menos
                  <KeyboardArrowUpIcon />
                </button>
              )}
            </div>

            {actualEpisode?.attachment !== null && (
              <div
                className="serieScreen_attachment"
                onClick={() => openAttachment(actualEpisode.attachment.url)}
              >
                <PictureAsPdfIcon />
                <div className="serieScreen_attachment__info">
                  <h5 href={`${baseURL}${actualEpisode?.attachment?.url}`}>
                    {actualEpisode.attachment.filename}
                  </h5>
                  <p>{getFilesize(actualEpisode.attachment.filesize)}</p>
                </div>
              </div>
            )}
          </div>
          <div className="serieScreen__playlist">
            <h4>{content?.title}</h4>
            <div className="serieScreen__playlist__content">
              {content?.episodes?.map((episode) => (
                <div
                  key={episode.id}
                  className={`${episode.id === actualEpisode?.id ? "serieScreen__playlist__info__active" : "serieScreen__playlist__info"}`}
                  onClick={() => changeEpisode(episode)}
                  ref={
                    episode.id === actualEpisode?.id
                      ? (el) =>
                          el?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                      : null
                  }
                >
                  {episode.id === actualEpisode?.id && (
                    <PlayArrowIcon
                      style={{
                        color: "white",
                        fontSize: 25,
                        position: "relative",
                        left: "0%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                  <LazyLoadImage
                    className={`${episode.id === actualEpisode?.id ? "serieScreen__playlist__info__thumbnail__active" : "serieScreen__playlist__info__thumbnail"}`}
                    width={100}
                    height={60}
                    effect="blur"
                    src={encodeURI(
                      `${baseURL}${episode?.thumbnail?.sizes?.card?.url}`,
                    )}
                    alt={episode?.thumbnail?.alt}
                  />
                  <h5>
                    {episode?.title?.length < 75
                      ? episode?.title
                      : episode?.title?.substring(0, 75) + "..."}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SerieDesktop
