import React, { useEffect, useState } from "react"
import "./Banner.css"
import { useNavigate } from "react-router-dom"

function Banner({ episodes }) {
  const [episode, setEpisode] = useState({})

  const navigate = useNavigate()

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * episodes.length)
    setEpisode(episodes[randomIndex])
  }, [episodes])

  const truncate = (text, maxCharacters) => {
    return text?.lenght > maxCharacters
      ? `${text.substr(0, maxCharacters - 1)}...`
      : text
  }

  const baseURL = process.env.REACT_APP_CMS_URL

  const handleImageClick = (content) => {
    navigate(`/watch`, { state: { content } })
  }

  return (
    <header
      className="banner"
      style={{
        backgroundSize: "cover",
        backgroundImage: episode?.thumbnail
          ? `url("${baseURL}${episode.thumbnail?.sizes?.card?.url}")`
          : "",
        backgroundPosition: "center center",
      }}
    >
      <div className="banner__contents">
        <h1 className="banner__title">{episode?.title}</h1>
        <div className="banner__buttons">
          <button
            onClick={() => handleImageClick(episode)}
            className="banner__button"
          >
            Assistir
          </button>
        </div>
        <h1 className="banner__description">
          {truncate(episode?.description, 150)}
        </h1>
      </div>

      <div className="banner--fadeBottom" />
    </header>
  )
}

export default Banner
