import React from "react"
import "./Sumary.css"
import WalletIcon from "@mui/icons-material/Wallet"
import PasswordIcon from "@mui/icons-material/Password"
import requests from "../api/requests"
import { useNavigate } from "react-router-dom"
import { logout } from "../app/features/userSlice"
import { errorSnackBar } from "../components/snackBarOptions"
import { useSnackbar } from "react-simple-snackbar"
import { useDispatch } from "react-redux"

function Sumary({ sections = [], scrollFunc, toggleDrawer }) {
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loadPortal = async () => {
    let resp = null
    try {
      resp = await requests.createPortalSession({
        returnURL: window.location.origin,
      })
    } catch (error) {
      openErrorSnackbar("Ocorreu um erro inexperado")
    }

    if (resp?.data?.url) {
      window.location.href = resp.data.url
    }
  }

  const handleLogout = async () => {
    try {
      await requests.logout()
      dispatch(logout())
    } catch (error) {
      openErrorSnackbar("Ocorreu um erro inexperado")
    }
  }

  return (
    <div className="sumary">
      <img className="sumary__logo" src="Primário_3.svg" alt="logo" />
      <div className="sumary__body">
        <ul>
          {sections.map((section, i) =>
            section?.contents?.length > 0 ? (
              <li key={i}>
                <div className="sumary__item" onClick={() => scrollFunc(i)}>
                  <button onClick={toggleDrawer(false)}>
                    {section?.title}
                  </button>
                </div>
              </li>
            ) : null
          )}
        </ul>
      </div>
      <div className="sumary__footer">
        <ul>
          <li>
            <div className="sumary__item">
              <button onClick={() => loadPortal()}>
                {" "}
                <WalletIcon /> Pagamentos
              </button>
            </div>
          </li>
          <li>
            <div className="sumary__item">
              <button onClick={() => navigate("/profile")}>
                {" "}
                <PasswordIcon /> Alterar Senha
              </button>
            </div>
          </li>
          <li>
            <button onClick={() => handleLogout()}>Sair</button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sumary
